@import "../../styles/variables.scss";

.impressum-container{
  text-align: left;
}

.impressum-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.impressum-subtitle {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.impressum-div {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;
}

.impressum-div:last-of-type {
  margin-bottom: 3rem;
}

/* ------------ MEDIA QUERIES ------------ */
@media (max-width: 499px) {
  .impressum-container{
    padding: 3rem 1rem;
    text-align: center;
  }
  
}
