@import "./variables";
// @import "./mixins";
@import "./globals";

.navbar__margin {
  margin-top: 12vh;
}

$font-family-heading: Montserrat, sans-serif;

.section__title {
  font-family: $font-family-heading;
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
  color: $text-color-base;
  text-align: center;

  @media (max-width: 330px) {
    font-size: $font-size-sm;
  }
}

.section__subtitle {
  font-family: $font-family-heading;
  font-size: $font-size-md;
  font-weight: $font-weight-light;
  color: $text-color-base;
  line-height: 1.5;

  @media (max-width: 330px) {
    font-size: $font-size-sm;
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: $font-size-md;
  font-family: $font-family-heading;
  transition: all 0.3s ease;
}

.btn--primary {
  width: 15rem;
  height: 3rem;
  padding: 0 1rem;
  border-radius: 30px;
  border: none;
  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  color: white;
  background-color: $primary-color;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken($primary-color, 10%);
    color: white;
  }

  @media (max-width: 330px) {
    width: 12rem;
    height: 2.5rem;
    font-size: $font-size-sm;
  }
}

.btn--secondary {
  width: 15rem;
  height: 3rem;
  padding: 0 1rem;
  border: none;
  border-radius: 10px;

  font-size: $font-size-md;
  font-weight: $font-weight-bold;
  color: $secondary-color;
  transition: all 0.3s ease;

  // &:hover{
  //   background-color: $primary-color;
  //   color: $secondary-color;
  // }
}
