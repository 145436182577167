@import "../../styles/variables.scss";

.prices-wrapper {
  .prices-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
    padding: 3rem 1.5rem;
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    @media screen and (max-width: 499px) {
      padding: 2rem 0rem;
    }

    .prices-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 60%;
      border-radius: 30px;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
      margin-bottom: 2rem;

      &:hover {
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);

        .prices-card-header {
          background-color: darken($primary-color, 10%);

          h1,
          h2,
          span {
            color: white;
          }
        }
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        justify-content: space-evenly;
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .prices-card-header {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 1.5rem;
        background-color: $card-color-1;
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
        padding-bottom: 1rem;
        border-radius: 30px 30px 0 0;

        h1,
        h2 {
          color: $secondary-color;
          text-align: center;
        }

        h1 {
          font-size: 2rem;
          font-weight: 800;

          @media screen and (max-width: 768px) {
            font-size: 1.8rem;
          }

          span {
            position: absolute;
            font-size: 1.2rem;
            font-weight: 500;
            color: $text-color-base;
            top: 3.9rem;
          }
        }

        h2 {
          font-size: 1.4rem;

          @media screen and (max-width: 768px) {
            font-size: 1.2rem;
          }
        }
      }

      .prices-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        width: 100%;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding: 1rem 0;
            border-bottom: 1px solid #ccc;

            &:last-child {
              border-bottom: none;
            }

            h3 {
              font-size: $font-size-lg;
              font-weight: $font-weight-medium;
              color: $text-color-base;
              padding-bottom: 0.5rem;
              border-bottom: 1px solid #ccc;
            }

            p {
              font-size: $font-size-base;
              color: $text-color-base;
              line-height: 1.5;
            }
          }
        }

        .prices-card-body-text {
          font-size: $font-size-base;
          color: $text-color-base;
          line-height: 1.5;
          margin-bottom: 1rem;
        }
      }

      .prices-card-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0rem 1.5rem 2rem 1.5rem;
        // border-top: 1px solid #ccc;
        border-radius: 0 0 30px 30px;

        button {
          font-size: $font-size-base;
          color: $text-color-base;
          line-height: 1.5;
          width: 80%;
          padding: 1rem;
          background-color: $primary-color;
          color: white;
          border: none;
          border-radius: 30px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-weight: 600;

          &:hover {
            background-color: darken($color: $primary-color, $amount: 10%);
          }
        }
      }
    }
  }

  .prices-intro-text {
    font-size: $font-size-base;
    color: $text-color-base;
    line-height: 1.5;
    margin-bottom: 3rem;
  }

  .prices-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    .prices-list-title {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
      color: $text-color-base;
      letter-spacing: 0.1rem;
      padding-bottom: 1rem;
    }

    ul {
      list-style: none;
      padding: 0 3rem;
      margin: 3rem 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 2rem;
      flex-wrap: wrap;

      @media screen and (max-width: 870px) {
        padding: 0;
      }

      .prices-list-card {
        flex: 1 1 calc(33.333% - 20px);
        flex-direction: column;
        width: 20rem;
        gap: 2rem;
        justify-content: space-between;
        align-items: flex-start;
        padding: 2rem;
        border-radius: 30px;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
        overflow: hidden;

        &:first-of-type {
          background-color: $card-color-1;
        }

        &:nth-of-type(2) {
          background-color: $card-color-2;
        }

        &:nth-of-type(3) {
          background-color: $card-color-3;
        }

        &:last-of-type {
          background-color: $card-color-1;
        }

        @media screen and (max-width: 870px) {
          width: 100%;
        }

        img {
          background-color: white;
          width: 3rem;
          height: auto;
          border-radius: 15px;
          padding: 0.5rem;
          margin-bottom: 1rem;
        }

        h3 {
          font-size: $font-size-lg;
          font-weight: $font-weight-medium;
          color: $text-color-base;
          padding-bottom: 0.5rem;
          margin-bottom: 1rem;
          @media screen and (max-width: 870px) {
            font-size: $font-size-md;
          }
        }

        p {
          font-size: $font-size-base;
          color: $text-color-base;
          line-height: 1.5;
        }

        .prices-list-card-btn {
          font-size: $font-size-base;
          color: $text-color-base;
          line-height: 1.5;
          width: 10rem;
          padding: 0.5rem 1rem;
          background-color: transparent;
          color: $secondary-color;
          border: 1px solid $secondary-color;
          border-radius: 30px;
          cursor: pointer;
          transition: background-color 0.3s;
          font-weight: 600;

          &:hover {
            background-color: $secondary-color;
            color: white;
          }
        }
      }
    }

    .prices-list-extra-info {
      display: none;
    }

    .prices-list-extra-info.visible {
      display: block;

      p {
        font-size: $font-size-base;
        color: $text-color-base;
        line-height: 1.5;
        margin-top: 1rem;
      }
    }
  }

  .prices-outro-text {
    font-size: $font-size-base;
    color: $text-color-base;
    line-height: 1.5;
    margin-top: 3rem;
  }
}
