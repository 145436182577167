@import "../../styles/variables.scss";

.por-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
  margin: 1rem 0;
  border-radius: 0.5rem;
  color: white;

  p {
    font-size: $font-size-base;
    line-height: 1.5;
    color: $text-color-base;
    margin-bottom: 0;
  }
}

.por-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  text-align: center;
  position: relative;

  .por-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 2rem;
    width: 100%;
    height: 100%;
    margin: 2rem 0;
    border-radius: 0.5rem;
    color: $text-color-base;

    @media screen and (max-width: 499px) {
      padding: 0rem;
    }

    .por-form-group {
      width: 100%;
      .form-floating {
        input {
          width: 100%;
          padding: 1rem;
          border-radius: 0.5rem;
          border: 1px solid $primary-color;
          font-size: $font-size-base;
          color: $text-color-base;
          margin-bottom: 1rem;
        }
      }
    }

    .por-form-checkbox-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 1rem;

      .por-form-checkbox-text {
        font-size: 1rem;
        font-weight: 400;
        margin-left: 0.5rem;
        text-align: left;
        line-height: 1.2;

        a {
          color: $primary-color;
          text-decoration: none;
          font-weight: 600;
        }
      }
    }

    .cta-por-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 1rem;
    }

    .por-form-submit-animation_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 1rem; /* Reduce padding */
      width: auto; /* Adjust width to fit content */
      height: auto; /* Adjust height to fit content */
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 0.5rem;
      z-index: 1000;
    
      .por-form-submit-info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    
        .por-form-submit-info-text {
          font-size: 1.2rem;
          font-weight: 400;
          color: $text-color-base;
          text-align: center;
        }
    
        button {
          width: 15rem;
          margin-top: 1rem;
          background-color: $secondary-color;
          color: white;
    
          &:hover {
            background-color: white;
            color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
    }
  }
}
