@import "../../styles/variables.scss";

// SERVICES - PERSONAL

.services-wrapper {
  display: flex;
  flex-direction: column;

  .services-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    @media screen and (max-width: 768px) {
      padding: 1rem 0rem;
    }

    .services-intro-text {
      font-size: $font-size-md;
      color: $text-color-base;
      margin-bottom: 0;
      line-height: 1.5;
    }
  }

  .services-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    @media screen and (max-width: 768px) {
      padding: 1rem 0rem;
    }

    h2 {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: $font-size-xl;
      margin-bottom: 2rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 1rem 0;
        // border-bottom: 1px solid #ccc;
        width: 100%;

        &:last-child {
          border-bottom: none;
        }

        h3 {
          font-size: $font-size-lg;
          font-weight: $font-weight-medium;
          padding-bottom: 0.5rem;
        }

        p {
          font-size: $font-size-md;
          color: $text-color-base;
          line-height: 1.5;
          width: 80%;
        }
      }
    }
  }

  .services-tips {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    @media screen and (max-width: 768px) {
      padding: 1rem 0rem;
    }

    h2,
    .services-tips-title {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
      margin-bottom: 2rem;
    }

    .services-tips-intro {
      font-size: $font-size-md;
      color: $text-color-base;
      margin-bottom: 2rem;
      line-height: 1.5;
      width: 75%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .tip-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem 0;
      border-bottom: 1px solid #ccc;
      margin-bottom: 1rem;
      width: 100%;

      &:last-child {
        border-bottom: none;
      }

      h2 {
        font-size: $font-size-lg;
        font-weight: $font-weight-medium;
        color: $text-color-base;
      }

      p {
        font-size: $font-size-md;
        color: $text-color-base;
        line-height: 1.5;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;

        li {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 1rem 0;
          // border-bottom: 1px solid #ccc;
          width: 100%;

          &:last-child {
            border-bottom: none;
          }

          h3 {
            font-size: $font-size-md;
            font-weight: $font-weight-medium;
            color: $text-color-base;
            padding-bottom: 0.5rem;
          }

          p {
            font-size: $font-size-base;
            color: $text-color-base;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
