@import "../../../styles/variables.scss";

.list-container-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  .list-container-content-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
    color: $text-color-base;
    letter-spacing: 0.1rem;
    padding-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0 3rem;
    margin: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .list-extra-info {
    display: none;
  }

  .list-extra-info.visible {
    display: block;

    p {
      font-size: $font-size-base;
      color: $text-color-base;
      line-height: 1.5;
      margin-top: 1rem;
    }
  }
}
