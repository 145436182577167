@import "../../styles/variables.scss";

.contact-us-info-wrapper {
  .contact-us-info-intro-text {
    font-size: $font-size-md;
    color: $text-color-base;
    margin-bottom: 0;
    line-height: 1.5;
    width: 100%;
  }
}
.contact-us-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem 0;
  column-gap: 3rem;
  row-gap: 3rem;
  text-align: center;

  @media screen and (max-width: 844px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;
  }

  .contact-us-info-box-address {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: $card-color-1;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    width: 100%;

    .contact-us-info-box-title {
      font-size: 1.5rem;
      font-weight: $font-weight-medium;
      margin-bottom: 1rem;
    }

    .contact-us-info-box-text-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      gap: 1rem;
      width: 100%;
    }

    .contact-us-info-box-text-item img {
      width: 2rem;
      height: 2rem;
      background-color: #fff;
      padding: 0.5rem;
      border-radius: 9px;
    }

    .contact-us-info-box-text-title {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .contact-us-info-box-openhours {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    background-color: $card-color-2;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    width: 100%;

    .contact-us-info-box-title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .contact-us-info-box-text-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;
      }
    }

    .contact-us-info-box-text-item img {
      width: 2rem;
      height: 2rem;
      margin-bottom: 1rem;
      background-color: #fff;
      padding: 0.5rem;
      border-radius: 9px;
    }

    .contact-us-info-box-text-title {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .contact-us-info-box-map {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .contact-us-info-box-map-img img {
      width: 100%;
      height: 20rem;
      object-fit: cover;
      border-radius: 20px;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 450px) {

        width: 100%;
        height: 15rem;
      }
    }
  }
}

.contact-us-info-box-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.contact-us-info-box-text-item img {
  width: 2rem;
  height: 2rem;
}

.contact-us-info-box-text-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.contact-us-info-box-map {
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
}

.contact-us-info-box-map-img img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

/* ----------- CONTACT US - STEPS ----------- */
/* ----- IMAGE BOX ----- */

.contact-us-step-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.contact-us-step-img-box img {
  width: auto;
  height: 20rem;
}

@keyframes updown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate {
  animation: updown 2s ease-in-out infinite;
}

/* ----- TEXT BOX ----- */
.contact-us-step-text-box {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.contact-us-step-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.contact-us-step-description {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.contact-us-step-button {
  width: 20rem;
  margin-bottom: 1rem;
}

/* ------------ MEDIA QUERIES ------------ */

@media screen and (max-width: 448px) {
  .contact-us-container {
    padding: 0 1rem;
  }

  /* ----- STEPS - IMAGE----- */

  .contact-us-step-img-box img {
    width: auto;
    height: 20rem;
  }

  /* ----- STEPS - TEXT----- */

  .contact-us-step-description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .contact-us-info-box-map {
    width: auto;
    height: 15rem;
  }

  /* ----- INFO - IMAGE----- */
  .contact-us-info-box-map-img img {
    width: auto;
    height: 15rem;
  }
}

@media screen and (max-width: 378px) {
  .contact-us-info-box-map-img img {
    width: auto;
    height: 12rem;
  }
}

@media screen and (max-width: 300px) {
  .contact-us-info-box-map-img img {
    width: auto;
    height: 10rem;
  }
}
