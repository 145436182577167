@import "../../../styles/variables.scss";

#home-services-container {
  padding: 5rem;

  @media screen and (max-width: 991px) {
    padding: 3rem 1rem;
  }

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem;
  }
}

.home-services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 3rem 0;
  padding: 0;

  @media screen and (max-width: 899px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
  }

  .home-services-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    gap: 1rem;
    padding: 2rem 0;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    img {
      background-color: white;
      border-radius: 30%;
      margin: 0 auto;
      padding: 1rem;
      width: 60px;
    }

    .home-services-title {
      font-size: 1.4rem;
      font-weight: 600;
      color: $secondary-color;
      text-align: center;
      padding: 0 1rem;
      margin-bottom: 0;
    }

    .home-services-text {
      font-size: 1.2rem;
      font-weight: 400;
      color: $text-color-base;
      text-align: center;
      padding: 1rem 2rem;
      line-height: 1.5;
      height: 220px;

      @media screen and (min-width: 1050px) {
        height: 150px;
      }

      @media screen and (max-width: 899px) {
        height: 100px;
      }

      @media screen and (max-width: 445px) {
        height: 125px;
      }

      @media screen and (max-width: 399px) {
        height: 150px;
      }
    }

    .home-services-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      text-decoration: none;
      color: $secondary-color;
      font-weight: 600;
      transition: all 0.2s;
      border-radius: 100px;
      border: 1px solid $secondary-color;
      width: 10rem;
      margin: 0 auto;
      font-size: 1.2rem;

      &:hover {
        background-color: $secondary-color;
        color: white;
      }
    }
  }
}
