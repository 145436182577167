@import "../../../styles/variables";

#home-testimonials-container {
  padding: 5rem ;
  background-color: #f0f0f0;
  border-radius: 20px;
  margin-bottom: 0;

  @media screen and (max-width: 1950px) {
    padding: 3rem 1rem;
    border-radius: 0;
  }

  h2{
    padding: 0 1rem;
  }
}
  .home-testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 3rem 0 0 0 !important;
    padding: 0 3.2rem;

    @media screen and (max-width: 899px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0 1.5rem;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0 1rem;
    }

    .home-testimonial-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      gap: 3rem;
      padding: 2rem 0;

      img {
        margin: 0 auto;
        width: 50px;
      }

      .home-testimonial-text {
        font-size: 1.2rem;
        font-weight: 400;
        color: $text-color-base;
        text-align: center;
        padding: 1rem 3rem ;
      }

      .home-testimonial-author {
        font-size: 1.2rem;
        font-weight: 600;
        color: $secondary-color;
        text-align: center;
        padding: 1rem;
      }
    }
  }
