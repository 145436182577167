//COLORS
$primary-color: #437FF2;
//options for primary color
$secondary-color: black;
$tertiary-color: #b84f06;
$quaternary-color: #fff;
$link-hover-color: #FFFBDB;
$text-color-base: black;
// #625834
// CARD COLORS
$card-color-1: #f4ece0;
$card-color-2: #f0f5ff;
$card-color-3: #f5f5f5;

//FONT FAMILIES

$font-family-heading: Montserrat, sans-serif;
$font-family-base: Roboto, sans-serif;

// FONT SIZES
$font-size-xs: 0.8rem;
$font-size-sm: 1rem;
$font-size-base: 1.1rem;
$font-size-md: 1.2rem;
$font-size-lg: 1.5rem;
$font-size-xl: 2rem;
$font-size-xxl: 2.5rem;

// FONT WEIGHTS
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-medium: 550;
$font-weight-bold: 600;
$font-weight-bolder: 700;

// BREAKPOINTS
$breakpoint-xxs: 320px;
$breakpoint-xs: 375px;
$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;