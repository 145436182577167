@import "../../styles/variables.scss";

.about-container {
  .about-intro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    padding: 0;
    width: 100%;
    height: auto;
    margin: 1rem 0;
    border-radius: 0.5rem;
    color: white;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .about-intro-img-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 40%;
      height: auto;

      @media screen and (max-width: 1115px) {
        max-width: 300px;
        margin: 0 auto;
      }

      img {
        max-width: 300px;
        width: auto ;
        height: auto;
        margin: 0 1rem;
        border-radius: 0.5rem;
      }

      h3 {
        font-size: $font-size-lg;
        font-weight: 600;
        margin: 1rem 0 0;
        font-family: Open-Sans, sans-serif;
        color: $text-color-base;
        text-align: center;
      }

      p {
        font-size: $font-size-base;
        line-height: 1.5;
        color: $text-color-base;
        margin-bottom: 0;
        text-align: center;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .about-intro-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 0;
      p {
        font-size: $font-size-base;
        line-height: 1.8;
        color: $text-color-base;
        margin-bottom: 2rem;
      }
    }
  }

  .about-why-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 1rem 0;
    border-radius: 0.5rem;
    color: white;

    .about-why-us-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
      width: 100%;
      padding: 0;

      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
      }

      .about-why-us-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding: 1.5rem;
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 768px) {
          padding: 1rem;
          gap: 1rem;
          flex-direction: row;
        }

        @media screen and (max-width: 499px) {
          padding: 1rem;
          gap: 1rem;
          flex-direction: column;
        }

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        }

        &:first-of-type {
          background-color: $card-color-1;
        }

        &:nth-of-type(2) {
          background-color: $card-color-2;
        }

        &:nth-of-type(3) {
          background-color: $card-color-3;
        }

        img {
          width: 4rem;
          height: 4rem;
          border-radius: 30%;
          padding: 1rem;
          background-color: white;
        }

        .why-us-text-wrapper{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          width: 100%;

          @media screen and (max-width: 768px) {
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: flex-start;
          }

          @media screen and (max-width: 499px) {
            gap: 0.5rem;
            justify-content: center;
            align-items: center;
          }

          h3 {
            font-size: $font-size-lg;
            font-weight: 600;
            color: $text-color-base;
            padding-bottom: 0.5rem;
            // border-bottom: 1px solid #ccc;
            text-align: center;
            height: 65px;

            @media screen and (max-width: 768px) {
              height: auto;
              text-align: left;
            }
          }
        }

        h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: $font-size-lg;
          font-weight: 600;
          color: $text-color-base;
          padding-bottom: 0.5rem;
          // border-bottom: 1px solid #ccc;
          text-align: center;
          height: 65px;

          @media screen and (max-width: 768px) {
            height: auto;
          }
        }
      }
    }

    p {
      font-size: $font-size-base;
      line-height: 1.5;
      color: $text-color-base;
      margin-bottom: 0;
    }
  }
}
