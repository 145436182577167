@import "../../styles/_variables.scss";

.navbar-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  // color: white;
  padding: 1rem 3rem;
  z-index: 9999;

  @media screen and (max-width: 499px) {
    padding: 1rem;
  }

  &.scrolled {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem 3rem;
    background-color: white;

    @media screen and (max-width: 499px) {
      padding: 1rem;
    }
  }
}

.bg-myBlue {
  background-color: transparent;

  @media screen and (max-width: 1199px) {
    background-color: white;
  }
}

.navbar-brand {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: white;
  border: 1px solid black;
  backdrop-filter: blur(10px); /* Blur effect */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 9px;
  font-weight: 800;

  @media screen and (max-width: 499px) {
    font-size: 1rem;
  }

  img {
    width: 3rem;
    height: auto;

    @media screen and (max-width: 499px) {
      width: 2rem;
    }
  }
}

.navbar-brand:hover {
  color: $primary-color;
}

.navbar-nav {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.navbar .navbar-toggler {
  border: none;
  outline: none;
  color: white !important;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9px;
}

.navbar-toggle .open .navbar-toggler:active .navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.navbar .navbar-toggler .collapsed {
  width: 2.5rem;
  height: 2.5rem;
}

.navbar-link .nav-link {
  justify-content: center;
}

.navbar-link,
.navbar-dropdown-text {
  color: $text-color-base;
  margin: auto 0;
  text-align: center;
  font-size: 1.2rem;
}

.navbar-link:hover,
.navbar-link .nav-link:hover,
.navbar-dropdown-text:hover {
  color: $secondary-color;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle {
  color: $text-color-base !important;
}

.dropdown-toggle:hover {
  color: $secondary-color !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  margin-top: 1.1rem;
  border-radius: 0;
  background-color: white;
  padding: 0;
  border: none;
  overflow-x: hidden;
}

.dropdown-item {
  color: #001f3f;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.dropdown-item:first-of-type {
  padding-top: 1rem;
}

.dropdown-item:last-of-type {
  padding-bottom: 1rem;
}

.dropdown-item:hover {
  background-color: $secondary-color;
  color: white;
  padding: 1rem;
}

.navbar-call-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background-color: transparent;
  border: 1px solid $secondary-color;
  border-radius: 30px;
  color: white;
  font-weight: 600;
  padding: 2rem 2.5rem;

  .nav-link {
    color: black;
  }
}

// .navbar-call-btn a {
// }

// .navbar-call-btn span {
//   width: 5rem;
// }

/* --------- MEDIA QUERIES --------- */
@media screen and (max-width: 1199px) {
  .navbar-link .nav-link {
    text-align: flex-start;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: center;
    padding: 0.5rem 0;
    width: 100%;
  }

  .nav-item .show .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    margin-top: 0;
    width: 105%;
    text-align: center;
    background-color: #fff;
    border: none;
  }

  .dropdown-item {
    color: #001f3f;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }

  .dropdown-item:hover {
    background-color: $secondary-color;
    color: white;
    padding: 1rem;
  }

  .navbar-call-btn {
    margin: 0 auto;
    padding: 0rem;
    width: 10rem;
  }
}
