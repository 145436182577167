@import "../../../styles/_variables.scss";

.hero-container {
  max-height: 90vh;
  overflow: hidden;

  @media screen and (max-width: 899px) {
    height: 75vh;
    overflow: hidden;
  }

  @media screen and (max-width: 800px) and (min-width: 600px) {
    height: auto
  }


  @media screen and (max-width: 599px) {
    height: 75vh;
    overflow: hidden;
  }
}

.hero-container .carousel-item {
  max-width: 100%;
  width: 100%;
  height: 85vh;
  overflow: hidden;
  border-radius: 30px;
  padding: 1rem;

  @media screen and (min-width: 1050px) {
    height: 90vh;
    overflow: hidden;
  }

  @media screen and (max-width: 1050px) {
    overflow:auto
  }

  @media screen and (max-width: 800px) and (min-width: 600px) {
    height: auto
  }

  .hero-img {
    /* height: 100%; */
    width: 100%;
    // max-width: 100%;
    object-fit: cover;
    position: relative;
    border-radius: 30px;
  }

  @keyframes slideIn {
    0% {
      transform: translate(-50%, -100%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  .hero-img-text-box {
    position: absolute;
    top: 75%;
    left: 50%;
    width: 55%;
    height: 325px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Blur effect */
    transform: translate(-50%, -50%);
    text-align: center;
    animation: slideIn 0.5s ease-in-out;
    z-index: 3;
    border-radius: 30px;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 1050px) {
      top: 75%;
    }

    @media screen and (max-width: 1050px) {
      top: 70%;
      width: 75%;
    }

    @media screen and (min-width: 850px) and (max-width: 1050px) {
      top: 85%;
      width: 75%;
      height: 200px;
    }

    @media screen and (max-width: 800px) and (min-width: 600px) {
      top: 70%;
      width: 75%;
      height: 200px;
    }
    @media screen and (max-width: 599px) {
      top: 70%;
      width: 75%;
      height: 200px;
    }

    .hero-img-title {
      font-size: 4rem;
      font-weight: 600;
      color: $secondary-color;
      text-align: center;

      @media screen and (max-width: 1050px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 599px) {
        font-size: 1.5rem;
      }
    }

    .hero-img-description {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.5;
      color: $secondary-color;
      text-align: center;
      padding: 0 3rem;

      @media screen and (max-width: 1050px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 599px) {
        display: none;
      }
    }

    .hero-img-buttons_wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;

      @media screen and (max-width: 599px) {
        flex-direction: column;
      }

      .hero-img-button {
        border-radius: 30px;
        background-color: $primary-color;
        color: white;
        width: 15rem;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0.5rem;
        text-transform: none;
        margin-top: 0;

        &:hover {
          background-color: darken($primary-color, $amount: 20%);
          color: white;
        }

        @media screen and (max-width: 768px) {
          width: 12rem;
          font-size: 1rem;
        }
      }

      .secondary-button {
        background-color: transparent;
        color: black;
        border: 1px solid $secondary-color;

        &:hover {
          background-color: darken($secondary-color, $amount: 20%);
          color: white;
        }
      }
    }
  }
}

.carousel-indicators {
  button {
    background-color: $secondary-color;
    width: 10px;
    height: 10px;
    margin: 0 0.5rem;

    &.active {
      background-color: $primary-color;
    }
  }
}

@media screen and (max-width: 768px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none !important;
  }
}

/* ------- MEDIA QUERIES ------- */
@media screen and (min-width: 2050px) {
  #lastHomePic {
    top: 0rem;
  }
}

@media screen and (min-width: 720px) and (max-width: 1050px) {
  .hero-img-text-box {
    top: 50% !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 1050px) {
  .hero-img-text-box {
    top: 40%;
  }

  .hero-img-title {
    text-align: center;
  }

  .hero-img-description {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    padding: 0 1rem;
  }
}

@media screen and (max-width: 599px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: none;
  }

  .hero-container .carousel-item {
    height: 75vh;
    overflow: hidden;
  }

  .hero-img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }

  .hero-img-text-box {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    text-align: center;
    animation: slideIn 0.5s ease-in-out;
    z-index: 3;
  }

  .hero-img-title {
    text-align: center;
  }

  .hero-img-description {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    padding: 0 1rem;
  }
}
