@import "../../../styles/variables.scss";

.home-referencies-container {
  /* background-color: #f0f0f0; */
  padding: 3rem 0 !important;

  h2 {
    padding: 0 1rem;
  }
}

.home-referencies-title {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3rem;
}

.home-referencies-logo {
  height: 4rem;
  margin: 3rem 8rem;
}
