@import "../../../styles/variables.scss";

.list-card {
  flex: 1 1 calc(33.333% - 20px);
  flex-direction: column;
  width: 20rem;
  gap: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &:first-of-type {
    background-color: $card-color-1;
  }

  &:nth-of-type(2) {
    background-color: $card-color-2;
  }

  &:nth-of-type(3) {
    background-color: $card-color-3;
  }

  &:nth-of-type(4) {
    background-color: $card-color-1;
  }

  &:nth-of-type(5) {
    background-color: $card-color-2;
  }

  &:nth-of-type(6) {
    background-color: $card-color-3;
  }

  &:nth-of-type(7) {
    background-color: $card-color-1;
  }

  &:nth-of-type(8) {
    background-color: $card-color-2;
  }

  &:nth-of-type(9) {
    background-color: $card-color-3;
  }

  @media screen and (max-width: 870px) {
    width: 100%;
  }

  img {
    background-color: white;
    width: 3rem;
    height: auto;
    border-radius: 15px;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: $font-size-lg;
    font-weight: $font-weight-medium;
    color: $text-color-base;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 870px) {
      font-size: $font-size-md;
    }
  }

  p {
    font-size: $font-size-base;
    color: $text-color-base;
    line-height: 1.5;
  }

  .list-card-btn {
    font-size: $font-size-base;
    color: $text-color-base;
    line-height: 1.5;
    width: 10rem;
    padding: 0.5rem 1rem;
    background-color: transparent;
    color: $secondary-color;
    border: 1px solid $secondary-color;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600;

    &:hover {
      background-color: $secondary-color;
      color: white;
    }
  }
}
