@import "../../../styles/variables.scss";

.page-title-container {
  padding: 0rem;
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;
  // margin-top: 5.1rem;

  @media screen and (max-width: 1199px) {
    padding: 3rem 0rem;
    margin-bottom: 0;
    
  }


  .page-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    // border-radius: 30px 30px 0 0;
    position: relative;
    // background-color: $card-color-1;
  }

  // .page-title-text-wrapper {
  //   position: relative; /* Ensure the pseudo-element is positioned relative to this container */
  //   padding-bottom: 1.5rem; /* Add some padding to make space for the line */
  //   text-align: center; /* Center the title text */
  // }

  // @media screen and (max-width: 768px) {
  //   padding: 3rem 1rem 2rem 1rem;
  //   margin-bottom: 0;
  // }
}

.page-title-text {
  font-size: 4rem;
  font-weight: 800;
  text-align: center;
  font-family: Open-Sans, sans-serif;
  letter-spacing: 0.5rem;
  color: #001f3f;
  // text-transform: uppercase;
  margin-bottom: 0;
}

// .page-title-text::after {
//   content: "";
//   display: block;
//   width: 5rem;
//   height: 0.5rem;
//   background-color: #001f3f;
//   margin: 0.5rem auto;
//   display: block;
//   width: 50px; /* Fixed width of the line */
//   height: 7px; /* Height of the line */
//   background: #333;
//   border-radius: 9px; /* Gradient from blue to light blue */
//   margin: 0 auto; /* Center the line */
//   position: absolute;
//   bottom: 0;
//   left: 50%;
//   transform: translateX(-50%);
// }

@media screen and (max-width: 768px) {
  // .page-title-container {
  //   padding: 3rem 1rem 2rem 1rem;
  // }
  .page-title-text {
    font-size: 2.4rem;
  }
}

.custom-shape-divider-bottom-1724163208 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1724163208 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 59px;
}

.custom-shape-divider-bottom-1724163208 .shape-fill {
  fill: #ffffff;
}
