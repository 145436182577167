@import "../../../styles/main.scss";

.cta-por-container {
  padding: 3rem 0;
  width: 100%;
  height: 400px;
  margin: 2rem 0;
  gap: 1.5rem;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  @media screen and (max-width: 599px) {
    height: 100%;
  }

  .cta-por-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: $card-color-2;
    margin: 0;
    padding: 3rem 1.5rem 3rem 1.5rem;
    gap: 1.5rem;
    border-radius: 20px;
    text-align: left;
    
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 1.5rem;
      text-align: center;
    }

    .cta-por-header {
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
      letter-spacing: 0.1rem;
      color: $text-color-base;
      margin-bottom: 1rem;
    }

    .cta-por-text {
      font-size: $font-size-md;
      font-weight: $font-weight-light;
      letter-spacing: 0.1rem;
      color: $text-color-base;
      margin-bottom: 0;
    }
  }
  .cta-por-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    .btn--primary {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15rem;
      height: 3rem;
      padding: 1rem;
      border-radius: 30px;
      background-color: $secondary-color;
      font-size: $font-size-md;
      letter-spacing: 0.1rem;
      color: white;
      transition: all 0.3s ease;

      &:hover {
        background-color: darken($color: $primary-color, $amount: 10%);
        color: white;
      }
    }
  }

  .cta-por-contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    height: 100%;
    padding: 0;
    gap: 1rem;

    @media screen and (max-width: 1024px) {
      flex-direction: row;
      width: 100%;
    }

    @media screen and (max-width: 599px) {
      flex-direction: column;
    }

    .cta-por-contact-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: $card-color-3;
      width: 100%;
      height: 100%;
      gap: 1rem;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      letter-spacing: 0.1rem;
      padding: 0 1rem;
      border-radius: 20px;
      color: $text-color-base;
      margin-bottom: 0;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 1rem;
        gap: 0.5rem;

        p {
          margin-bottom: 0;

          img {
            width: 25px;
            height: 25px;
            margin-bottom: 1rem;
          }
        }

        img {
          width: 25px;
          height: 25px;
          margin-bottom: 1rem;
        }
      }

      .rarrow {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
      }
    }
  }
}
