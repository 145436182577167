$font-family-base: Lato, sans-serif;

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 1.1rem;
  box-sizing: border-box;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Roboto, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Open-sans, sans-serif;
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
}

p {
  margin-bottom: 0;
}
