@import "../../styles/variables";

/* --------------------- FOOTER ---------------------*/
/* ------------ FOOTER CONTAINER AND GRID ------------ */

.outer-div {
  width: 100%;
}
.footer-container {
  background-color: $secondary-color;
  color: white;
  padding: 3rem 5rem 1rem 5rem;
  /* max-width: 120rem; */
  margin: 0 auto;

  .footer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;

    @media screen and (max-width: 599px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
/* ------------ FOOTER - SHARED ------------ */

.footer-heading {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    text-align: left;
    margin-bottom: 1rem;

    a {
      text-decoration: none;
      color: white;
      font-size: 1rem;
    }
  }
}

/* ------------ FOOTER - LOGO COLUMN ------------ */

.logo-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  justify-content: center;
  text-align: center;
  max-width: 10rem;
  margin: 0 auto;
}

.footer-logo-img {
  width: 100%;
  height: auto;
}

/* ------------ FOOTER - SITES COLUMN ------------ */

.sites-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;

  @media screen and (max-width: 599px) {
    align-items: center;

    .footer-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        text-align: center;
      }
    }
  }
}

/* ------------ FOOTER - SERVICES COLUMN ------------ */

.services-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;

  @media screen and (max-width: 599px) {
    display: none;
  }
}

/* ------------ FOOTER - CONTACT ROW ------------ */

.footer-contact-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-size: 1rem;

  @media screen and (max-width: 820px) {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  @media screen and (max-width: 599px) {
    flex-direction: column;
    gap: 0;
  }

  p {
    padding: 0 1rem;
    margin-bottom: 0;
    border-right: 1px solid white;

    @media screen and (min-width: 599px) and (max-width: 652px) {
      &:nth-child(2) {
        border-right: none;
      }
    }

    @media screen and (max-width: 599px) {
      border-right: none;
      padding: 0.5rem 0;
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover{
        color: $primary-color;
      }
    }
  }

  p:last-child {
    border-right: none;
  }
}

/* ------------ FOOTER - SOCIALS ROW ------------ */

.footer-socials-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-size: 0.8rem;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;

    .footer-socials-icon {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;

      &:first-of-type {
        margin-left: 1rem;
      }
    }
  }
}
/* ------------ FOOTER - DOCS ROW ------------ */
.footer-container .docs-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-size: 0.8rem;
}

.footer-docs-links {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.footer-link {
  text-decoration: none;
  color: white;
  font-size: 0.8rem;
}

.footer-link:hover {
  color: $primary-color;
}

/* ------------ FOOTER - COPYRIGHT ROW ------------ */

.footer-container .copyright-row {
  width: 100%; /* Takes full width */
  text-align: center; /* Center the content horizontally if desired */
  padding: 1rem 0; /* Adjust padding as needed */
  color: white;
  font-size: 0.8rem;
  /* Any other styles you may want to apply */
  border-top: 1px solid lightgray;
}

.copyright-text {
  margin: 0;

  a {
    text-decoration: none;
    color: $primary-color;
  }
}

/* --------------------- MEDIA QUERIES --------------------- */

@media screen and (max-width: 950px) {
  .footer-container {
    padding: 3rem 2rem 1rem 2rem;
  }
}

@media screen and (max-width: 800px) {
  .footer-docs-links {
    width: 100%;
  }
}
